<template>
	<div class="home">
		<keep-alive>
			<router-view router-view class="home_top"></router-view>
		</keep-alive>
		<div class="tabbar_wrap" v-if="$route.meta.showTab">
			<div class="tab_item_wrap" :class="{ 'div-active': selected_index == 0 }" @click="changepage(0)">
				<img v-if="selected_index == 0" class="tab_img" alt=""
					src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/tabbar_icon/tabbar_index_sel_icon.png" />
				<img v-else class="tab_img" alt=""
					src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/tabbar_icon/tabbar_index_icon.png" />
				<span>首页</span>
			</div>
			<div class="tab_item_wrap" :class="{ 'div-active': selected_index == 1 }" @click="changepage(1)">
				<img v-if="selected_index == 1" class="tab_img" alt=""
					src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/tabbar_icon/tabbar_streamer_sel_icon.png" />
				<img v-else class="tab_img" alt=""
					src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/tabbar_icon/tabbar_streamer_icon.png" />
				<span>通告</span>
			</div>
			<!-- <div class="tab_item_wrap" :class="{ 'div-active': selected_index == 2 }" @click="changepage(2)">
				<img v-if="selected_index == 2" class="tab_img" alt=""
					src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/tabbar_icon/tabbar_live_sel_icon.png" />
				<img v-else class="tab_img" alt=""
					src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/tabbar_icon/tabbar_live_icon.png" />
				<span>订单</span>
			</div>
			<div class="tab_item_wrap" :class="{ 'div-active': selected_index == 4 }" @click="changepage(4)">
				<div class="tab_msg">
					<div class="unread" v-if="unread">{{unread}}</div>
					<img v-if="selected_index == 4" class="tab_img" alt=""
							 src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/tabbar_icon/tabbar_news_sel_icon.png" />
					<img v-else class="tab_img" alt=""
							 src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/tabbar_icon/tabbar_news_icon.png" />
				</div>
				<span>消息</span>
			</div> -->
			<div class="tab_item_wrap" :class="{ 'div-active': selected_index == 3 }" @click="changepage(3)">
				<img v-if="selected_index == 3" class="tab_img" alt=""
					src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/tabbar_icon/tabbar_mine_sel_icon.png" />
				<img v-else class="tab_img" alt=""
					src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/tabbar_icon/tabbar_mine_icon.png" />
				<span>我的</span>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				selected_index: 0,
			};
		},
		computed:{
			unread(){
				return this.$store.state.total_unread_num
			}
		},
		created() {
			// this.$router.push('/main')
			// console.log(this.$route);
			this.$store.dispatch("getinvited", this.$route.query);
		},
		methods: {
			changepage(index) {
				if (index == this.selected_index) {
					return;
				} else if (index == 0) {
					this.$router.push("/main");
				} else if (index == 1) {
					this.$router.push("/plaza");
				} else if (index == 2) {
					this.$router.push("/order");
				} else if (index == 3) {
					// this.$router.push("/mine");
					this.$router.push("/shareCode");
				} else if (index == 4) {
					this.$router.push("/message");
				}
				this.selected_index = index;
			},
		},
		watch: {
			$route(to, from) {
				console.log("=====================",to)
				if (to.path=="/shareCode") {
					this.selected_index = 3;
				}
				if (to.path=="/plaza") {
					this.selected_index = 1;
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.tabbar_wrap {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100px;
		background: #fff;
		display: flex;
		justify-content: space-between;
		align-items: center;
		box-shadow: 0 0 20px 1px rgba(0,0,0,0.05);

		.tab_item_wrap {
			height: 100%;
			width: 25%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			font-size: 20px;
			font-weight: bold;
			color: #8a8e99;
		}
	}

	.tab_msg {
		position: relative;
		.unread {
			position: absolute;
			top: -7px;
			right: -10px;
			width: 35px;
			height: 35px;
			border-radius: 50%;
			background-color: #FF6685;
			color: #fff;
			font-size: 26px;
			font-weight: 600;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	.tab_img {
		height: 46px;
		width: 46px;
	}

	.div-active {
		color: #000 !important;
	}
</style>
